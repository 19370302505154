import React from 'react'
import SolutionsFeed from '../SolutionsFeed/SolutionsFeed'
import './SolutionsContent.scss'

export class SolutionsContent extends React.Component {
  render() {
    const { solutions } = this.props
    return (
      <div className="solutions-content">
        <SolutionsFeed solutions={solutions} />
      </div>
    )
  }
}

export default SolutionsContent
