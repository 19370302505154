import React from 'react'
import './ServicesHero.scss'

export class ServicesHero extends React.Component {
  render() {
    return (
      <div className="services-hero">
        <div className="overlay" />
        <div className="text">
          <h1>Help for every stage of your digital transformation journey</h1>
        </div>
      </div>
    )
  }
}

export default ServicesHero
