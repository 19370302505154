import React from 'react'
import './Container.scss'

const Container = props => {
  return (
    <div
      id={props.id}
      className={'section-wrap ' + props.color + ' ' + props.type}
    >
      <section>{props.children}</section>
    </div>
  )
}

export default Container
