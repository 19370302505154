import React, { Component } from 'react'
import { Link } from '@reach/router'
import './CallToActionBanner.scss'
import { FaCheckCircle, FaBolt } from 'react-icons/fa'

export default class CallToActionBanner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hideForm: false,
    }
  }

  showThankYou = e => {
    this.setState({ hideForm: true })
  }

  renderSignup() {
    let el
    if (this.state.hideForm) {
      el = (
        <div className="thanks-text">
          <div className="thanks-icon">
            <FaCheckCircle />
          </div>
          <div>
            Thanks for signing up! Keep an eye out for cloud native tips, case
            studies, and news!
          </div>
        </div>
      )
    } else {
      el = (
        <div className="sign-up-form-wrap">
          <div className="text">
            Sign up to get cloud native knowledge direct to your inbox
          </div>
          <form
            onSubmit={this.showThankYou}
            action="https://rackner.us7.list-manage.com/subscribe/post?u=ee22a531a3c1aa608d2cc0fd7&amp;id=fb43f626ad"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            target="_blank"
            id="EMAIL SIGN UP FORM"
            noValidate
          >
            <input type="hidden" name="oid" value="00D4T000000FZaP" />
            <input type="hidden" name="retURL" value="http://rackner.com" />
            <input
              placeholder="Email Address"
              type="email"
              id="email"
              name="email"
            />
            <button
              ype="submit"
              className="btn-raised-light-blue-inverse shadow"
            >
              SUBMIT
            </button>
          </form>
        </div>
      )
    }
    return el
  }

  renderBoost() {
    let el
    if (this.state.hideForm) {
      el = (
        <div className="thanks-text">
          <div className="thanks-icon">
            <FaCheckCircle />
          </div>
          <div>
            Thanks for signing up! Keep an eye out for cloud native tips, case
            studies, and news!
          </div>
        </div>
      )
    } else {
      el = (
        <div className="boost-wrap">
          <div className="text">
            Take your software product to the next level with Boost
          </div>
          <Link to={'/boost/'}>
            Try <FaBolt />
            <span className="italic">Boost</span>
          </Link>
        </div>
      )
    }
    return el
  }

  renderNormal(text, route, linkText, icon, ahref) {
    let Icon
    Icon = icon
    return (
      <React.Fragment>
        <div className="overlay" />
        {text ? <div className="text">{text}</div> : null}
        {route ? (
          <Link to={route}>
            {icon ? <Icon /> : null}
            {linkText}
          </Link>
        ) : (
          <a href={ahref}>
            {icon ? <Icon /> : null}
            {linkText}
          </a>
        )}
      </React.Fragment>
    )
  }

  render() {
    const { type, text, route, linkText, icon, ahref } = this.props
    return (
      <div className={'cta-banner ' + type}>
        {type === 'signup'
          ? this.renderSignup()
          : type === 'boost'
            ? this.renderBoost()
            : this.renderNormal(text, route, linkText, icon, ahref)}
      </div>
    )
  }
}
