import React from 'react'
import './SolutionsFeed.scss'
import SolutionsCard from '../SolutionsCard/SolutionsCard'

class SolutionsFeed extends React.Component {
  constructor(props) {
    super(props)
    this.feedInputChanged = this.feedInputChanged.bind(this)
    this.state = {
      value: '',
    }
  }

  feedInputChanged(ev) {
    let value = ev.target.value
    this.setState({
      input: value,
    })
  }

  textSearch(solutions) {
    let results = []
    if (!this.state.input) {
      return solutions
    }
    solutions.forEach(s => {
      if (!s) {
        return
      }
      if (
        s.node.title.toLowerCase().indexOf(this.state.input.toLowerCase()) > -1
      ) {
        results.push(s)
      } else {
        let tags = s.node.tags
        let tagMatch = false
        tags.forEach(tag => {
          if (
            tag.title.toLowerCase().indexOf(this.state.input.toLowerCase()) > -1
          ) {
            tagMatch = true
          }
        })
        if (tagMatch) {
          results.push(s)
        }
      }
    })
    return results
  }

  renderSolutionResults(solutions) {
    let array = solutions
    let chunkSize = 3
    let chunkedArray = [].concat.apply(
      [],
      array.map(function(elem, i) {
        return i % chunkSize ? [] : [array.slice(i, i + chunkSize)]
      })
    )
    return chunkedArray.map((chunk, index) => {
      return (
        <div key={index} className="solutions-feed-row row">
          {chunk.map((subChunk, index2) => {
            return (
              <div key={index2} className="col-sm-4">
                <SolutionsCard solution={subChunk.node} />
              </div>
            )
          })}
        </div>
      )
    })
  }

  render() {
    const { solutions } = this.props
    if (!solutions.length) {
      return <div />
    }
    return (
      <div className="solutions-feed">
        {this.props?.hideHeader ? (
          <></>
        ) : (
          <>
            <h1>Solutions</h1>
          </>
        )}
        {this.renderSolutionResults(this.textSearch(solutions))}
      </div>
    )
  }
}

export default SolutionsFeed
