import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import { graphql } from 'gatsby'
import '../templates/base.scss'
import Layout from '../components/Layout/Layout'
import Container from '../components/Container'
import CallToActionBanner from '../components/CallToActionBanner/CallToActionBanner'
import SolutionsContent from '../components/Solutions/SolutionsContent/SolutionsContent'
import ServicesHero from '../components/ServicesHero/ServicesHero'
import SEO from '../components/SEO'

const Solutions = ({ data }) => {
  const postNode = {
    title: `Solutions - ${config.siteTitle}`,
  }
  const solutions = data && data.allContentfulSolution.edges

  return (
    <Layout>
      <SEO postNode={postNode} pagePath="Insights" customTitle />
      <Helmet>
        <title>{`Solutions - ${config.siteTitle}`}</title>
      </Helmet>
      <ServicesHero />
      <Container color={'light-grey'}>
        <SolutionsContent solutions={solutions} />
      </Container>
      <CallToActionBanner
        linkText={'View our Case Studies'}
        route={'/insights?filter=casestudies'}
      />
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulSolution(limit: 100) {
      edges {
        node {
          title
          slug
          tags {
            title
            slug
          }
          hero {
            title
            subTitle
            callToAction
            callToActionLink
            background {
              title
              fluid(maxWidth: 1800) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
          metaDescription {
            internal {
              content
            }
          }
          body {
            content {
              nodeType
              content {
                value
              }
            }
          }
        }
      }
    }
  }
`

export default Solutions
