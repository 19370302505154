import React from 'react'
import { Link } from 'gatsby'
import './SolutionsCard.scss'

export class SolutionsCard extends React.Component {
  render() {
    const { solution } = this.props
    const { tags, title, slug, hero } = solution
    return (
      <Link
        style={{ textDecoration: 'none' }}
        key={title}
        to={'/solutions/' + slug}
      >
        <div className="solutions-card">
          <div
            className="hero-wrap"
            style={{
              backgroundImage: 'url(' + hero?.background?.fluid.src + ')',
            }}
          >
            <div className="overlay" />
          </div>
          <h2 className="title">{title}</h2>
          <div className="text">{hero.title}</div>
          <div className="buffer" />
          <div className="tags-wrap">
            {tags.map(tag => (
              <div key={tag.slug} className="tag">
                {tag.title}
              </div>
            ))}
          </div>
        </div>
      </Link>
    )
  }
}

export default SolutionsCard
